
export default {
  data() {
    return {
      terms: this.initialValue(),
    }
  },
  methods: {
    initialValue() {
      if (this.$route.query && this.$route.query.search) {
        return this.$route.query.search
      } else {
        return null
      }
    },
    onChange() {
      if (this.terms) {
        this.addQuery('search', this.terms)
      } else {
        this.removeQuery('search')
      }
    },
    addQuery(name, value) {
      const newQuery = Object.assign({}, this.$route.query)
      newQuery[name] = value
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      })
    },
    removeQuery(name) {
      const newQuery = Object.assign({}, this.$route.query)
      delete newQuery[name]
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      })
    },
  },
}
