
import utils from '@/mixins/utils'
import Dropdown from '@/components/form/Dropdown'
import Search from '@/components/form/Search'

export default {
  mixins: [utils],
  components: {
    Dropdown,
    Search,
  },
  props: {
    filters: {
      type: Array,
      default: () => {
        return []
      },
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clear(event) {
      this.$router.push({
        path: this.$route.path,
        query: null,
      })
    },
  },
}
