
export default {
  data() {
    return {
      selected: this.initialValue(),
    }
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    initialValue() {
      if (this.$route.query && this.$route.query[this.filter.name]) {
        return this.filter.data.find(
          (f) => f.code === this.$route.query[this.filter.name]
        )
      } else {
        return {
          label: 'All',
          code: 'all',
          id: 'all',
        }
      }
    },
    onChange(val, name) {
      const value = val.code === 'all' ? null : val.code
      if (value) {
        this.addQuery(name, value)
      } else {
        this.removeQuery(name)
      }
    },
    addQuery(name, value) {
      const newQuery = Object.assign({}, this.$route.query)
      newQuery[name] = value
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      })
    },
    removeQuery(name) {
      const newQuery = Object.assign({}, this.$route.query)
      delete newQuery[name]
      this.$router.push({
        path: this.$route.path,
        query: newQuery,
      })
    },
  },
}
