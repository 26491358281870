
import RecipeTeaser from '@/components/teasers/RecipeTeaser'
import Filters from '@/components/Filters'
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  components: {
    RecipeTeaser,
    Filters,
  },
  data() {
    return {
      backdoor: 0,
      show: 20,
      filters: [
        {
          name: 'type',
          field: 'recipe_type',
          data: this.createOptions(this.$store.state.recipeTypes),
        },
        {
          name: 'method',
          field: 'recipe_method',
          data: this.createOptions(this.$store.state.recipeMethods),
        },
        {
          name: 'spirit',
          field: 'spirits',
          data: this.createOptions(this.$store.state.spiritCategories),
        },
      ],
    }
  },
  props: {
    manual: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  mounted() {
    this.backdoor++
  },
  computed: {
    filteredRecipes() {
      let recipes = this.recipes
      if (this.$route.query && this.backdoor && this.$refs.filter) {
        const query = Object.keys(this.$route.query)
        const filters = this.$refs.filter.filters

        query.forEach((name) => {
          const filter = filters.find((filter) => filter.name === name)

          if (filter) {
            const id = filter.data.find(
              (f) => f.code === this.$route.query[name]
            ).id

            recipes = recipes.filter((recipe) => {
              let found = false
              if (Array.isArray(recipe.attributes[filter.field].data)) {
                found = recipe.attributes[filter.field].data.find(
                  (item) => parseInt(item.id) === parseInt(id)
                )
              } else {
                found =
                  parseInt(recipe.attributes[filter.field].data.id) ===
                  parseInt(id)
              }
              return found
            })
          }
        })
      }

      const limit = this.data.limit || 3
      if (limit !== -1) {
        recipes = recipes.slice(0, limit)
      }

      if (this.$route.query && this.$route.query.search) {
        recipes = recipes.filter((recipe) => {
          return recipe.attributes.title
            .toLowerCase()
            .includes(this.$route.query.search.toLowerCase())
        })
      }

      return recipes
    },
    recipes() {
      return this.data.recipes && this.data.recipes.data.length > 0
        ? this.$store.state.recipes.filter((recipe) => {
            return this.data.recipes.data.some((f) => {
              return f.id === recipe.id
            })
          })
        : !this.manual
        ? this.$store.state.recipes.filter(
            (recipe) => Number(recipe.id) !== this.parentId
          )
        : []
    },
  },
}
